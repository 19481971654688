import React from 'react';

import AdminLayout from '../../adminlayout/index';

import SEO from 'components/seo/index';
// import Side from 'layout/sidebaritem';

import Sidebar from 'components/admin/sidebaritem';

const SidebarPage = ({ location }) => {
  const dataValue = (location.state && location.state.editData !== undefined) ? location.state.editData : null;
  return (
    <>
      <SEO title='Forests By Heartfulness admin' />
      <AdminLayout>
        <div>
          <Sidebar data={dataValue} />
        </div>
      </AdminLayout>

    </>
  );
};

export default SidebarPage;
